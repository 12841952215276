<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório de Atendimento televet
        </h3>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row justify-content-center">
        <div class="col-2">
          <v-row justify="center">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Mês relatório"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                :min="dataLimite.min"
                :max="dataLimite.max"
                type="month"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <!-- <v-date-picker v-model="picker" type="month"></v-date-picker> -->
          </v-row>
        </div>
        <div class="col-3">
          <v-btn
            elevation="2"
            class="btn-width"
            color="primary"
            :disabled="!validForm() || loading"
            @click="gerarExcel()"
          >
            <span v-if="!loading">Gerar Relátório</span>
            <v-progress-circular
              :width="3"
              :size="20"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogLoading" width="500" persistent>
      <div class="card-style">
        <h3 class="mb-3">Processando, aguarde.</h3>
        <v-progress-linear indeterminate color="#1daf80"></v-progress-linear>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import RelatorioTelevetService from "@/services/relatorio_televet_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    services: {
      relatorioTelevetService: RelatorioTelevetService.build(),
    },
    loading: false,
    menu: false,
    date: "",
    mounth: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    dataLimite: {
      min: "",
      max: "",
    },
    dialogLoading: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  mounted() {
    this.getTimeTelevet();
  },
  methods: {
    getTimeTelevet() {
      this.services.relatorioTelevetService
        .getDataLimite()
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.dataLimite.max = resp.data.dt_max;
            this.dataLimite.min = resp.data.dt_min;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${this.mounth[parseInt(month - 1)]} - ${year}`;
    },
    validForm() {
      console.log("date", this.date);
      if (this.date) {
        return true;
      } else {
        return false;
      }
    },
    gerarExcel() {
      this.loading = true;
      this.dialogLoading = true;
      const dataRel = this.date.replaceAll("-", "") + "01";
      this.services.relatorioTelevetService
        .excelRelatorioTelevet(dataRel)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao Gerar Relatório.",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Relatório gerado com sucesso.",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio_atendimento_televet.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao Gerar Relatório.",
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.dialogLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.btn-width {
  width: 190px;
}
.card-style {
  background-color: white;
  padding: 4px 0px 0px;
}
</style>
