import HTTPService from "@/services/http_service.js";

export default class InfoUserService extends HTTPService {
  static build() {
    return new InfoUserService();
  }

  getUserInfo(idUsuario, idPerfil) {
    switch (parseInt(idPerfil)) {
      //Admin
      case 1:
        return this.get(`adm/usuario/${idUsuario}`);
      //Veterinario
      case 2:
        return this.get(`medico/usuario/${idUsuario}`);
      //Assistente
      case 3:
        return this.get(`assistente/usuario/${idUsuario}`);
      //Cliente
      case 4:
        return this.get(`cliente/usuario/${idUsuario}`);
      //Recepcao
      case 56:
        return this.get(`recepcionista/usuario/${idUsuario}`);
      //Supervisor
      case 57:
        return this.get(`spv/usuario/${idUsuario}`);
      //Prefeitura
      case 6:
        return this.get(`prefeitura/usuario/${idUsuario}`);
      default:
        break;
    }
  }
}
