import HTTPService from "@/services/http_service.js";

export default class RelatorioTelevetService extends HTTPService {
  static build() {
    return new RelatorioTelevetService();
  }

  getDataLimite() {
    console.log("foi");
    return this.get(`requisicao/televet/dtminmax`);
  }

  // pdfRelatorioAtendimentosProfissionalQuantitativo(
  //   dataInicial,
  //   dataFinal,
  //   unidade,
  //   profissional
  // ) {
  //   const requestBody = {
  //     dataInicial,
  //     dataFinal,
  //     unidade,
  //     profissional
  //   };

  //   return this.post('relatorio/atendimento-quantitativo/pdf', requestBody, {
  //     responseType: 'blob',
  //   }).then((resp) => {
  //     if (resp.status !== 200) return resp;
  //     return resp.blob();
  //   });
  // }

  excelRelatorioTelevet(dateRelatorio) {
    return this.get(
      `relatorio/televet/atendimentos/mes/excel/${dateRelatorio}`
      // {
      //   responseType: "blob",
      // }
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  // return await this.get(
  //   `relatorio/estatistico/cadastro-tutor/excel/${dataInicial}/${dataFinal}/${unidadeId}`
  // ).then((resp) => {
  //   if (resp.status != 200) return resp;
  //   return resp.blob();
  // });
}
