<template>
  <div>
    <div class="p-5" v-if="!loadingData">
      <form class="p-lg-3 row">
        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Nome Completo</label>
          <v-text-field
            :error-messages="error.nome"
            v-model="nome"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Seu nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Email</label>
          <v-text-field
            :error-messages="error.email"
            v-model="email"
            :rules="[rules.required, rules.email]"
            :type="'email'"
            placeholder="Escreva seu email"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Senha</label>
          <v-text-field
            :error-messages="error.senha"
            v-model="senha"
            background-color="grey lighten-2"
            :type="'password'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Celular:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_1"
            v-model="telefone_1"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Fixo:</label>
          <v-text-field
            v-mask="'(##)####-####'"
            :error-messages="error.telefone_2"
            v-model="telefone_2"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CPF</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            :error-messages="error.cpf"
            v-model="cpf"
            :type="'text'"
            placeholder="000.000.000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
          <span class="text-muted">ex: "123.456.7890-12"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>RG</label>

          <v-text-field
            :error-messages="error.rg"
            v-model="rg"
            :type="'text'"
            placeholder="00.000.000-0"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xx.xxx.xxx-x"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Data de nascimento</label>

          <v-text-field
            :error-messages="error.data_nascimento"
            v-model="dataNascimento"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'date'"
            :outlined="false"
          />

          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label class="">Gênero</label>
          <v-radio-group :error-messages="error.genero" v-model="genero">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
            <v-radio label="Outros" value="O" />
          </v-radio-group>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Estado civil</label>

          <v-text-field
            :error-messages="error.estadoCivil"
            v-model="estadoCivil"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />

          <span class="text-muted"
            >ex: Casado, Solteiro, Divorciado, Viúvo"</span
          >
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Profissão</label>
          <v-text-field
            :error-messages="error.profissao"
            v-model="profissao"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Indicado por</label>
          <v-text-field
            :error-messages="error.indicado_por"
            v-model="indicado_por"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            :error-messages="error.cep"
            v-model="cep"
            @blur="searchCep"
            :type="'text'"
            placeholder="00000-000"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xxxxx-xxx"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Logradouro</label>

          <v-text-field
            :error-messages="error.logradouro"
            v-model="logradouro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Bairro</label>

          <v-text-field
            :error-messages="error.bairro"
            v-model="bairro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Numero</label>

          <v-text-field
            :error-messages="error.numero"
            v-model="numero"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Cidade</label>

          <v-text-field
            :error-messages="error.cidade"
            v-model="cidade"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Estado</label>
          <v-text-field
            :error-messages="error.estado"
            v-model="estado"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Complemento</label>

          <v-text-field
            :error-messages="error.complemento"
            v-model="complemento"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <!--        <div class="col-lg-6 form-group clearfix mb-3">-->
        <!--          <label>Perfil</label>-->
        <!--          <v-select outlined :items="perfisItems" v-model="perfilId"/>-->
        <!--        </div>-->

        <!--        <div class="col-lg-6 form-group clearfix mb-3">-->
        <!--          <label>Unidades</label>-->
        <!--          <v-select outlined :items="unidadesItems"  v-model="unidadeId"/>-->
        <!--        </div>-->

        <div
          class="col-lg-4 form-group clearfix"
          style="padding-top: 0 !important"
        >
          <label>Unidade</label>
          <v-combobox
            v-model="unidadesSelecionadas"
            :items="unidades"
            item-text="text"
            item-value="value"
            label="Selecione a Unidade"
            multiple
            outlined
          ></v-combobox>
        </div>

        <div
          class="col-lg-2 form-group clearfix"
          style="padding-top: 0 !important"
        >
          <label>Status</label>
          <v-select
            outlined
            :items="statusSelect"
            item-text="descricao"
            item-value="id"
            v-model="status"
          />
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Referência</label>

          <v-text-field
            :error-messages="error.referencias"
            v-model="referencias"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarSupervisor"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
    <div v-else class="p-5">
      <v-progress-circular indeterminate color="primary" size="100" />
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import PerfilService from "../../services/perfil_service";
import UnidadeService from "../../services/unidade_service";
import SupervisorService from "../../services/supervisao_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      //Validação de Senha
      loading: false,
      // Formulário
      formCadastro: "",
      nome: "",
      email: "",
      telefone_1: "",
      telefone_2: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      profissao: "",
      indicado_por: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      senha: "",
      avatarAdministrativo: "",
      perfilId: 57,
      unidadeId: 0,
      error: {
        nome: "",
        email: "",
        telefone_1: "",
        telefone_2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        profissao: "",
        indicado_por: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      perfis: [],
      unidades: [],
      unidadesSelecionadas: [],
      services: {
        perfilService: PerfilService.build(),
        unidadeService: UnidadeService.build(),
        supervisorService: SupervisorService.build(),
      },
      loadingData: false,
      statusSelect: [
        { descricao: "Ativo", id: 1 },
        { descricao: "Inativo", id: 2 },
      ],
      status: 1,
    };
  },
  async mounted() {
    this.formCadastro = document.forms[0];
    this.loadingData = true;
    const [perfis] = await Promise.all([
      this.services.perfilService.getPerfilList(),
      //this.services.unidadeService.getUnidadeList()
    ]);
    this.loadingData = false;
    this.perfis = perfis;
    //this.unidades = unidades;

    await this.setUnidades();
  },
  computed: {
    perfisItems() {
      return this.perfis.map((perfil) => ({
        text: perfil.descricao,
        value: perfil.perfilId,
      }));
    },
    unidadesItems() {
      return this.unidades.map((u) => ({
        text: u.nomeFantasia,
        value: u.pessoaJuridicaId,
      }));
    },
  },
  methods: {
    async setUnidades() {
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          console.log(response);
          this.unidades = response.map((u) => ({
            value: u.pessoaJuridicaId,
            text: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    async cadastrarSupervisor() {
      if (!this.formCadastro.checkValidity()) {
        return;
      }
      this.loading = !!1;
      const supervisorId = await this.services.supervisorService.cadastrarSupervisor(
        {
          ativo: this.status,
          nome: this.nome,
          email: this.email,
          rg: this.rg,
          genero: this.genero,
          data_nascimento: this.dataNascimento,
          profissao: this.profissao,
          estado_civil: this.estadoCivil,
          cep: this.cep,
          numero: this.numero,
          complemento: this.complemento,
          referencias: this.referencias,
          cpf: this.cpf,
          telefone_1: this.telefone_1,
          telefone_2: this.telefone_2,
          id_perfil: this.perfilId,
          bairro: this.bairro,
          cidade: this.cidade,
          estado: this.estado,
          logradouro: this.logradouro,
          senha: this.senha,
          id_clinica: this.unidadesSelecionadas.map((u) => u.value).toString(),
        }
      );
      //console.log(this.unidadesSelecionadas.map((u) => u.value).toString());
      //console.log({administrativoId})
      this.loading = !!0;
      if (~~supervisorId > 0) {
        this.zerarFormulario();
        this.$_ACTIONS_showSnackbarMessage({
          message: "O usuário foi cadastrado com sucesso.",
          color: "sucess",
        });
        this.$emit("response");
        this.$emit("close");
      } else if (~~supervisorId === 0 && typeof supervisorId === "object") {
        const errors = supervisorId;
        Object.keys(errors).forEach((error) => {
          if (errors[error]) {
            this.error[error] = errors[error];
            console.log(errors[error]);
            console.log(this.error);
          }
        });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Ocorreu um erro.",
          color: "error",
        });
      }
    },
    mostrarFeedback() {
      return (status) => (body) => {
        this.zerarErros();
        if (body.message) {
          if (status === 200 || status === 201) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.zerarFormulario();
            this.$emit("response");
            this.$emit("close");
          }
          if (status === 400 && body.errors) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "error",
            });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
          }
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          this.zerarFormulario();
          this.$emit("response");
          this.$emit("close");
        }
      };
    },

    //Upload de fotos de perfil
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarAdministrativo = this.$refs.pictureInput.file;
      } else {
        console.error("API File Reader não suportada: use o <form>");
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
