<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between mt-2 mx-10">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Comandas</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="comandas"
      class="mx-10"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn class="btn-style-green" @click="pdfRelatorio(item)"
          >Exportar PDF</v-btn
        >
        <v-btn
          class="btn ml-5"
          v-if="perfil.id == 1"
          :disabled="!item.comandaEstaAberta"
          @click="fecharComanda(item)"
          >Fechar comanda</v-btn
        >
        <v-icon class="mx-8" @click="showDetalhesComandas(item)">
          mdi mdi-magnify
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="setComandas"> Atualizar </v-btn>
      </template>
    </v-data-table>

    <template v-if="dialogDetalhesComandas">
      <v-dialog
        v-model="dialogDetalhesComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogDetalhesComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comanda Detalhes </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <modal-comanda-detalhes
            :comanda="comandaDetalhes"
            :tutor="tutor"
            @close="() => (dialogDetalhesComandas = false)"
          />
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import ModalComandaDetalhes from "@/components/pacientes/modal_comanda_detalhes_teste.vue";

export default {
  mixins: [Mixin],
  components: {
    ModalComandaDetalhes,
  },
  data: () => ({
    dialogDetalhesComandas: false,
    comandas: [],
    isRec: false,
    comandaDetalhes: {},
    loading: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "comanda_id",
      },
      {
        text: "Pet",
        align: "start",
        sortable: false,
        value: "petNome",
      },
      {
        text: "Unidade",
        align: "start",
        sortable: false,
        value: "unidade",
      },
      { text: "Data de Inicio", value: "dataInicio", sortable: false },
      { text: "Data Fechamento", value: "dataFim", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    comandaService: ComandaService.build(),
    perfil: "",
  }),
  props: {
    tutor: {
      type: Object,
      required: true,
    },
    petId: {},
  },
  watch: {
    tutor() {
      this.comandas = [];
      this.setComandas();
    },
  },
  async mounted() {
    this.perfil = JSON.parse(sessionStorage.vuex).perfil;
    this.isRec = this.$_GETTER_perfil.descricao === "recepçao";
    await this.setComandas();
  },
  methods: {
    pdfRelatorio(item) {
      this.loading = true;
      this.comandaService
        .pdfRelatorioComandaById(item.comanda_id)
        .then((resp) => {
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${item.petNome}-${item.data_criacao}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .then(() => (this.loading = false));
    },
    async setComandas() {
      this.loading = true;

      let response = [];

      const unidade = await JSON.parse(sessionStorage.vuex).unidade;
      if (this.petId) {
        response = await this.comandaService.getComandasByPetId(
          this.tutor.id_cliente,
          unidade.id,
          this.petId
        );
      } else if ([1, 57].includes(this.perfil.id)) {
        response = await this.comandaService.getComandasByClienteId(
          this.tutor.id_cliente
        );
      } else {
        response = await this.comandaService.getComandasRecepcaoByClienteId(
          this.tutor.id_cliente,
          unidade.id
        );
      }

      if (response.length <= 0) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Nenhuma comanda em aberto.",
          color: "error",
        });
      }

      this.comandas = response.map((comanda) => {
        console.log(comanda);
        return {
          ...comanda,
          petNome: comanda.pet_nome,
          unidade: comanda.unidade_nome_fantasia,
          dataInicio:
            comanda.data_criada
              .split("-")
              .reverse()
              .join("/") +
            " " +
            comanda.hora_criada,
          dataFim:
            comanda.data_fechamento !== "1900-01-01"
              ? comanda.data_fechamento
                  .split("-")
                  .reverse()
                  .join("/") +
                " " +
                comanda.hora_fechamento
              : "Em aberto",
          comandaEstaAberta: comanda.data_fechamento === "1900-01-01",
        };
      });
      this.loading = false;
    },
    showDetalhesComandas(comanda) {
      this.dialogDetalhesComandas = true;
      this.comandaDetalhes = comanda;
    },
    async fecharComanda(comanda) {
      const response = await this.comandaService.fecharComanda(
        comanda.comanda_id ?? 0
      );
      if (response.status == 200) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A comanda foi fechada com sucesso.",
          color: "sucess",
        });
        await this.setComandas();
      } else {
        const error = await response.json();
        this.$_ACTIONS_showSnackbarMessage({
          message: error.message,
          color: "error",
        });
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.btn-style-green {
  display: inline-flex;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px !important;
  padding: 10px !important;
  border-radius: 50px;
}
</style>
