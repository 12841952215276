<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Meus Recebimentos
        </h3>
      </div>
    </div>

    <v-row class="mt-2 mx-1">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" v-if="[1, 57].includes(perfil)">
        <v-select
          :items="unidades"
          label="Unidade"
          item-value="id"
          v-model="unidadeSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex mt-7 text-left">
        <v-btn
          small
          style="color:#fff;background-color:#1daf80;border-radius:50px;"
          @click="getRecebimentos"
          class="mr-2"
          >Buscar</v-btn
        >
        <!-- <v-btn small color="primary" class="ml-3" @click="exportarPDF"
          >Exportar PDF</v-btn
        > -->
        <v-speed-dial
          v-model="fab"
          :direction="'bottom'"
          :open-on-hover="false"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              style="color:#fff;background-color:#188ae2;border-radius:50px;"
              small
              dark
            >
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <span v-else>
                Exportar
              </span>
            </v-btn>
          </template>
          <v-btn dark color="green" style="width: 7rem;" @click="exportarPDF">
            PDF
          </v-btn>
          <v-btn
            dark
            color="indigo"
            style="width: 7rem;"
            @click="exportarExcel"
          >
            EXCEL
          </v-btn>
        </v-speed-dial>
      </v-col>
    </v-row>
    <div v-if="recebimentos.length > 0">
      <label class="w-100 text-left ml-3 mt-5 text-lg-h6 gray--text">
        Totais
      </label>
      <v-simple-table class="border">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th
                class="text-center"
                v-for="tipoPagamento in formasPagamento"
                :key="tipoPagamento.id_forma_pagamento"
              >
                {{ tipoPagamento.descricao }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td
                v-for="tipoPagamento in formasPagamento"
                :key="tipoPagamento.id_forma_pagamento"
              >
                R$ {{ tipoPagamento.total }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <label class="w-100 text-left ml-3 mt-5 text-lg-h6 gray--text">
      Relatório detalhado
    </label>
    <template>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="recebimentos"
        :options.sync="options"
        :server-items-length="totalList"
        v-on:update:sort-by="options.ordenacao = true"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        class="border"
      ></v-data-table>
    </template>
  </div>
</template>
<script>
import ComandaService from "../services/comanda_service";
import UnidadeService from "../services/unidade_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Data e Hora", value: "data" },
        { text: "Valor", value: "valor" },
        { text: "Forma de pagamento", value: "descricao" },
        { text: "Responsável", value: "reponsavel" },
        { text: "Cliente", value: "cliente" },
      ],
      recebimentos: [],
      services: {
        comandaService: ComandaService.build(),
        unidadeService: UnidadeService.build(),
      },
      formasPagamento: [],
      menu: false,
      datas: null,
      unidades: [],
      unidadeSelected: "",
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      totalList: 10,
      perfil: null,
      usuarioId: null,
      fab: false,
    };
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.getRecebimentosByUnidade();
      },
      deep: true,
    },
  },
  mounted() {
    this.perfil = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    this.usuarioId = JSON.parse(sessionStorage.getItem("vuex")).usuario.id;
    this.setUnidades();
  },
  methods: {
    pickerDate(value) {
      console.log(value);
    },
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    getRecebimentos() {
      this.loading = true;
      if ([1, 57].includes(this.perfil)) {
        this.getRecebimentosByUnidade();
        return;
      }
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
      const dataAtual = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("");
      const dataInicial = this.datas
        ? this.datas[0].split("-").join("")
        : dataAtual;
      const dataFinal = this.datas
        ? this.datas[1].split("-").join("")
        : dataAtual;

      this.services.comandaService
        .getRecebimentos(unidadeId, dataInicial, dataFinal)
        .then((resp) => {
          const result = resp.data;
          this.formasPagamento = result.Totais;
          this.recebimentos = result.Recebimentos.map((recebimento) => {
            return {
              ...recebimento,
              valor: `R$ ${recebimento.valor}`,
              data: `${recebimento.data_criacao
                .split("-")
                .reverse()
                .join("/")} - ${recebimento.hora_criacao}`,
            };
          });
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    getRecebimentosByUnidade() {
      if (!this.unidadeSelected) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione uma unidade!",
          color: "error",
        });

        this.loading = false;
        return;
      }
      const dataAtual = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("");
      const dataInicial = this.datas
        ? this.datas[0].split("-").join("")
        : dataAtual;
      const dataFinal = this.datas
        ? this.datas[1].split("-").join("")
        : dataAtual;

      this.services.comandaService
        .getRecebimentosByUnidade(
          this.unidadeSelected,
          dataInicial,
          dataFinal,
          this.options
        )
        .then((resp) => {
          const result = resp.data;
          this.formasPagamento = result.Totais;
          this.recebimentos = result.Recebimentos.map((recebimento) => {
            return {
              ...recebimento,
              valor: `R$ ${recebimento.valor}`,
              data: `${recebimento.data_atualizacao
                .split("-")
                .reverse()
                .join("/")} - ${recebimento.hora_atualizacao}`,
            };
          });
          this.totalList = resp.pagination.num_rows;
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    exportarPDF() {
      this.loading = true;
      const dataAtual = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("");
      const dataInicial = this.datas
        ? this.datas[0].split("-").join("")
        : dataAtual;
      const dataFinal = this.datas
        ? this.datas[1].split("-").join("")
        : dataAtual;

      if ([1, 57].includes(this.perfil)) {
        this.services.comandaService
          .getPdfRecebimentosByUnidade(
            this.unidadeSelected,
            dataInicial,
            dataFinal
          )
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `recebimentos-${dataInicial}-${dataFinal}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      } else {
        const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
        this.services.comandaService
          .getPdfRecebimentos(unidadeId, dataInicial, dataFinal)
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `recebimentos-${dataInicial}-${dataFinal}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    exportarExcel() {
      this.loading = true;
      const dataAtual = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("");
      const dataInicial = this.datas
        ? this.datas[0].split("-").join("")
        : dataAtual;
      const dataFinal = this.datas
        ? this.datas[1].split("-").join("")
        : dataAtual;

      this.services.comandaService
        .getExcelRecebimentosByUnidade(
          this.unidadeSelected,
          dataInicial,
          dataFinal
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          link.download = `recebimentos-${dataInicial}-${dataFinal}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
