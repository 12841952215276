<template>
  <div class="account-pages w-100 mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow">
            <div class="card-body p-4">
              <div class="text-center mb-4">
                <a href="index.html">
                  <span>
                    <!-- Televet Logo -->
                    <!-- <img
                      src="@/assets/images/logo_televet.png"
                      alt=""
                      height="75px"
                      class="mb-3"
                    /> -->
                    <!-- Televet Anclivepa -->
                    <img
                      src="@/assets/images/logo-login.png"
                      alt=""
                      height="200px"
                    />
                  </span>
                </a>
              </div>

              <div class="pt-2 form-login">
                <div class="form-group mb-3">
                  <v-select
                    v-model="tipoPerfilSelecionado"
                    :items="tipoPerfis"
                    item-text="nome"
                    return-object
                    label="Selecione seu tipo de perfil"
                    class="mb-2"
                    outlined
                  >
                  </v-select>
                </div>
                <div class="form-group mb-3">
                  <label for="EmailUsuario">Usuário</label>
                  <input
                    class="form-control"
                    v-model="email"
                    type="email"
                    id="EmailUsuario"
                    required=""
                    placeholder="Escreva seu email"
                    :disabled="!perfilSelecionado"
                  />
                </div>

                <div class="form-group mb-3">
                  <label for="SenhaUsuario">Senha</label>
                  <input
                    class="form-control"
                    v-model="senha"
                    type="password"
                    required=""
                    id="SenhaUsuario"
                    placeholder="Senha"
                    :disabled="!perfilSelecionado"
                  />
                  <a class="text-muted float-right" @click="esqueceu_senha"
                    ><small>Esqueceu sua senha?</small></a
                  >
                </div>

                <br /><br />

                <div class="form-group mb-0 text-center">
                  <button
                    class="btn btn-app-primary btn-block btn-rounded width-lg font-weight-bold"
                    @click.prevent="login"
                    :disabled="!perfilSelecionado"
                  >
                    <span v-if="!loading"> Entrar </span>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </button>
                  <div class="justify-content-md-center mt-2">
                    <v-btn
                      v-if="this.$_GETTER_perfil.description === 'paciente'"
                      class="rounded-pill col-12"
                      @click="nova_conta"
                      text
                      :disabled="!perfilSelecionado"
                      plain
                    >
                      Criar Nova Conta
                    </v-btn>
                    <!-- <button
                      class="
                        btn btn-outline-app-primary btn-block btn-rounded
                        width-lg
                        font-weight-bold
                      "
                      @click="voltar"
                    >
                      Voltar
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
    <default-dialog
      :value="selectClinicas"
      color="green darken-3"
      title="Selecione sua unidade"
    >
      <div class="p-2">
        <div class="col-lg-12">
          <div class="mx-auto" v-if="!loadingSelectClinicas">
            <h3>Escolha a unidade em que você irá atender:</h3>
            <div class="mt-8">
              <v-row v-for="(unidade, index) in unidades" :key="index">
                <v-btn
                  class="btn btn-rounded my-1"
                  @click="entrarNoSistema(unidade)"
                >
                  {{ unidade.nome_fantasia }}
                </v-btn>
              </v-row>
            </div>
          </div>
          <v-progress-circular v-else indeterminate size="100" />
        </div>
      </div>
    </default-dialog>
  </div>
</template>

<script>
import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import Default from "../dialogs/default";
import UnidadeService from "../../services/unidade_service";
import PrefeituraService from "../../services/prefeitura_service";
import InfoUserService from "../../services/infouser_service";
import { global } from "@/config/constants";

// import axios from "axios";

export default {
  components: { DefaultDialog: Default },
  mixins: [Mixin, DadosCadastraisMixin],
  data: () => ({
    selectClinicas: false,
    loadingSelectClinicas: false,
    unidades: [],
    email: "",
    senha: "",
    loading: false,
    formValid: false,
    formLogin: {},
    responseData: {},
    unidadeRepository: UnidadeService.build(),
    prefeituraService: PrefeituraService.build(),
    infoUserService: InfoUserService.build(),
    tokenChamefacil: "",
    tipoPerfis: [
      { id: 56, description: "recepcionista", nome: "Recepção" },
      { id: 5, description: "administrativo", nome: "Administrativo" },
      { id: 57, description: "supervisor", nome: "Supervisor" },
      { id: 2, description: "medico", nome: "Médico" },
      { id: 3, description: "assistente", nome: "Assistente" },
      { id: 4, description: "paciente", nome: "Cliente" },
      { id: 6, description: "prefeitura", nome: "Prefeitura" },
    ],
    tipoPerfilSelecionado: "",
    perfilSelecionado: false,
    id_prefeitura: "",
  }),
  watch: {
    async tipoPerfilSelecionado() {
      await this.setPerfil(this.tipoPerfilSelecionado);
      this.perfilSelecionado = true;
    },
  },
  beforeMount() {
    this.$_ACTIONS_setUsuario({});
    this.$_ACTIONS_setToken("");
    this.$_ACTIONS_setUsuario({});
    localStorage.clear();
    sessionStorage.clear();
    global.api.baseUrlChamefacil = "";
    global.api.baseUrlChamefacil_api = "";
  },
  mounted() {
    localStorage.clear();
    sessionStorage.clear();
    global.api.baseUrlChamefacil = "";
    global.api.baseUrlChamefacil_api = "";
    this.formLogin = document.forms[0];
  },
  methods: {
    esqueceu_senha() {
      this.$router.replace("esqueceu-senha");
    },
    nova_conta() {
      // this.$router.replace("primeiro-pet");
      this.$router.replace("novo-cadastro");
    },
    voltar() {
      this.$_ACTIONS_perfil({ id: 0, description: "home" });
      this.$router.replace("home");
    },
    entrarNoSistema(unidade = {}) {
      console.log(unidade);
      this.$_ACTIONS_setUnidade({
        id: ~~unidade.unidade_id,
        nome: unidade.nome_fantasia || "",
        logradouro: unidade.logradouro || "",
        numero: unidade.numero || "sn",
        bairro: unidade.bairro || "",
        cidade: unidade.cidade || "",
        estado: unidade.estado || "",
        cep: unidade.cep || "",
        cnpj: unidade.cnpj || "",
        telefone: unidade.telefone_1 || "",
      });

      this.loadingSelectClinicas = true;
      const data = this.responseData;

      // this.tokenChamefacil = data.token_chamefacil;
      // localStorage.setItem("tokenChamefacil", data.token_chamefacil ?? "");

      localStorage.removeItem("infoPaciente");

      this.$_ACTIONS_setToken(data.token);
      this.$_ACTIONS_setUsuario({ id: data.id_usuario });

      // this.getPerfilId(data.id_usuario, data.id_perfil);

      if (this.$_GETTER_perfil.id == 6) {
        this.prefeituraService
          .getUsuarioPrefeituraById(data.id_usuario)
          .then((resp) => resp.json())
          .then((resp) => {
            return resp.data.id_prefeitura;
          })
          .then((id_prefeitura) => {
            this.prefeituraService
              .getUnidadesPrefeitura(id_prefeitura)
              .then((resp) => resp.json())
              .then((resp) => {
                console.log(resp);
                localStorage.setItem(
                  "unidades_prefeitura",
                  JSON.stringify(resp.data.clinicas)
                );
              });
          });
      }

      this.DADOS_CADASTRAIS_MIXIN_getDadosCadastrais();
      // if ([1, 58].includes(this.$_GETTER_perfil.id)) {
      if ([1, 57].includes(this.$_GETTER_perfil.id)) {
        if (JSON.parse(sessionStorage.vuex).unidade.id == 24) {
          this.$router.replace("telemedicina-disponibilidade");
        } else {
          this.$router.replace("acompanhar-filas");
        }
      } else if (this.$_GETTER_perfil.id === 3) {
        this.$router.replace({ name: "fila_atendimento" });
      } else if (this.$_GETTER_perfil.id === 2) {
        if (JSON.parse(sessionStorage.vuex).unidade.id == 24) {
          this.$router.replace("telemedicina-principal");
        } else {
          this.$router.replace({ name: "filasatendimentomedico" });
        }
      } else if (this.$_GETTER_perfil.id === 4) {
        // this.$router.replace({ name: "minhas_consultas" });
        this.$router.replace({ name: "dashboard_cliente" });
      } else if (this.$_GETTER_perfil.id === 56) {
        this.$router.replace({ name: "recepcao" });
      } else if (this.$_GETTER_perfil.id === 6) {
        this.$router.replace({ name: "pets" });
      } else {
        if (JSON.parse(sessionStorage.vuex).unidade.id == 24) {
          this.$router.replace("telemedicina-principal");
        } else {
          this.$router.replace("principal");
        }
      }
      if (Object.keys(unidade).length) {
        this.unidadeRepository
          .getUnidadeById(unidade.unidade_id)
          .then((resp) => {
            const unidade = resp.data.unidade[0];
            this.$_ACTIONS_setUnidade({
              id: ~~unidade.id,
              nome: unidade.nome_fantasia || "",
              razao_social: unidade.razao_social || "",
              logradouro: unidade.logradouro || "",
              numero: unidade.numero || "sn",
              bairro: unidade.bairro || "",
              cidade: unidade.cidade || "",
              estado: unidade.estado || "",
              cep: unidade.cep || "",
              cnpj: unidade.cnpj || "",
              telefone: unidade.telefone || "",
            });
          });
      }
    },
    login() {
      this.loading = true;
      // console.log({
      //   perfil: this.$_GETTER_perfil,
      // });
      const usuario = {
        email: this.email,
        senha: this.senha,
      };
      const authService = new AuthService();
      authService.login(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.responseData = body.data;
              this.unidades = body.data.unidades || [];
              // console.log(body.data);
              this.$_ACTIONS_perfil({
                id: body.data.id_perfil,
                descricao: body.data.descricao,
                documento: body.data.id_perfil == 2 ? body.data.documento : "",
              });
              // this.$_ACTIONS_setToken(body.data.token);
              // this.$_ACTIONS_setUsuario({ id: body.data.id_usuario });
              // this.getPerfilId(body.data.id_usuario, body.data.id_perfil);
              if (
                this.$_GETTER_perfil.id == 4 ||
                this.$_GETTER_perfil.id == 6
              ) {
                // Se for cliente
                this.entrarNoSistema();
                return;
              }
              // else {
              //   this.selectClinicas = true;
              // }
              if (this.unidades.length == 1) {
                this.entrarNoSistema(this.unidades[0]);
                return;
              }
              this.selectClinicas = true;
              // axios
              //   .post(global.api.baseUrlChamefacil + "/acesso/logar", {
              //     id: 0,
              //     Guid: "",
              //     Nome: "",
              //     Login: "teste",
              //   })
              //   .then((resp) => {
              //     this.tokenChamefacil = resp.data.Token;
              //     localStorage.setItem("tokenChamefacil", this.tokenChamefacil);
              //   });
              // .then(() => {
              //   const headers = { token: this.tokenChamefacil };
              //   axios
              //     .post(
              //       global.api.baseUrlChamefacil +
              //         "/localchamada/alocarlocal",
              //       { IdLocal: 2 },
              //       { headers }
              //     )
              //     .then((resp) => console.log(resp.data));
              // });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario,
        this.$_GETTER_perfil.description
      );
    },
    // getPerfilId(idUsuario, idPerfil) {
    //   this.infoUserService
    //     .getUserInfo(idUsuario, idPerfil)
    //     .then(async (response) => {
    //       if (response.status !== 200) return;
    //       const { data } = await response.json();
    //       console.log("perfil data", data);
    //       switch (parseInt(idPerfil)) {
    //         //Admin
    //         case 1:
    //           this.$_GETTER_perfil.usuario.data.medicoId = data.id;
    //           break;
    //         //Veterinario
    //         case 2:
    //           this.$_SET_usuario.teste = "teste";
    //           this.$_SET_usuario.data.medicoId = data.id;
    //           break;
    //         //Assistente
    //         case 3:
    //           localStorage.setItem.usuario.data.recepcaoId =
    //             data.dados.id_recepcao;
    //           break;
    //         //Cliente
    //         case 4:
    //           localStorage.setItem.usuario.data.recepcaoId =
    //             data.dados.id_recepcao;
    //           break;
    //         //Recepcao
    //         case 56:
    //           localStorage.setItem.usuario.data.recepcaoId =
    //             data.dados.id_recepcao;
    //           break;
    //         //Supervisor
    //         case 5:
    //           localStorage.setItem.usuario.data.recepcaoId =
    //             data.dados.id_recepcao;
    //           break;
    //         //Prefeitura
    //         case 6:
    //           localStorage.setItem.usuario.data.recepcaoId =
    //             data.dados.id_recepcao;
    //           break;
    //         default:
    //           break;
    //       }
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     })
    //     .finally(() => {});
    // },
    setPerfil(obj) {
      console.log(obj);
      this.$_ACTIONS_perfil(obj);
    },
  },
};
</script>
