var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"#3e682a","color":"#fff"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[(_vm.visualizar)?_c('span',{staticClass:"headline"},[_vm._v("Visualizar atendente")]):_c('span',{staticClass:"headline"},[_vm._v("Editar assistente")])]),_c('v-spacer'),_c('v-toolbar-items')],1),(!_vm.loadingData)?_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"p-lg-3 row"},[_c('div',{staticClass:"col-lg-4 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome Completo")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.nome,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Nome é obrigatório.'),
                _vm.validationService.isNotEmpty() ],"type":'text',"placeholder":"Seu nome completo"},model:{value:(_vm.atendente.nome),callback:function ($$v) {_vm.$set(_vm.atendente, "nome", $$v)},expression:"atendente.nome"}})],1),_c('div',{staticClass:"col-lg-4 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"emailCadastroUsuario"}},[_vm._v("Email")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.email,"disabled":true,"background-color":"grey lighten-2","readonly":"","rules":[
                _vm.validationService.required('Campo Email é obrigatório.'),
                _vm.validationService.isEmail('Email inválido.') ],"type":'email',"placeholder":"Escreva seu email"},model:{value:(_vm.atendente.email),callback:function ($$v) {_vm.$set(_vm.atendente, "email", $$v)},expression:"atendente.email"}})],1),_c('div',{staticClass:"col-lg-4 form-group clearfix mb-3"},[_c('label',[_vm._v("Perfil")]),_c('v-select',{staticStyle:{"padding-top":"0 !important"},attrs:{"placeholder":"Selecione o perfil do usuário","items":_vm.listaPerfis,"disabled":true,"rules":[_vm.rules.required]},model:{value:(_vm.atendente.id_perfil),callback:function ($$v) {_vm.$set(_vm.atendente, "id_perfil", $$v)},expression:"atendente.id_perfil"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone Celular:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#####-####'),expression:"'(##)#####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone1,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Telefone é obrigatório.'),
                _vm.validationService.isNotEmpty() ],"placeholder":"(99) 99999-9999"},model:{value:(_vm.atendente.telefone_1),callback:function ($$v) {_vm.$set(_vm.atendente, "telefone_1", $$v)},expression:"atendente.telefone_1"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone Fixo:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)####-####'),expression:"'(##)####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone2,"disabled":_vm.visualizar,"type":'text',"placeholder":"(99) 99999-9999"},model:{value:(_vm.atendente.telefone_2),callback:function ($$v) {_vm.$set(_vm.atendente, "telefone_2", $$v)},expression:"atendente.telefone_2"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CPF"}},[_vm._v("CPF")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cpf,"disabled":"","type":'text',"rules":[
                _vm.validationService.required('Campo CPF é obrigatório.'),
                _vm.validationService.isNotEmpty() ],"placeholder":"000.000.000-00"},model:{value:(_vm.atendente.cpf),callback:function ($$v) {_vm.$set(_vm.atendente, "cpf", $$v)},expression:"atendente.cpf"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"RG"}},[_vm._v("RG")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.rg,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.atendente.rg),callback:function ($$v) {_vm.$set(_vm.atendente, "rg", $$v)},expression:"atendente.rg"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',[_vm._v("Data de nascimento")]),_c('v-text-field',{staticStyle:{"margin":"0 5px","padding-top":"5px !important"},attrs:{"error-messages":_vm.error.data_nascimento,"disabled":_vm.visualizar,"clear-icon":"clear","rules":[
                _vm.validationService.required(
                  'Campo Data de nascimento é obrigatório.'
                ),
                _vm.validationService.isNotEmpty() ],"type":'date',"outlined":false},model:{value:(_vm.atendente.data_nascimento),callback:function ($$v) {_vm.$set(_vm.atendente, "data_nascimento", $$v)},expression:"atendente.data_nascimento"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"Dia/Mês/Ano\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{},[_vm._v("Gênero")]),_c('v-radio-group',{attrs:{"error-messages":_vm.error.genero,"rules":[
                _vm.validationService.required('Campo Gênero é obrigatório.') ],"disabled":_vm.visualizar},model:{value:(_vm.atendente.genero),callback:function ($$v) {_vm.$set(_vm.atendente, "genero", $$v)},expression:"atendente.genero"}},[_c('v-radio',{attrs:{"label":"Masculino","value":"M"}}),_c('v-radio',{attrs:{"label":"Feminino","value":"F"}}),_c('v-radio',{attrs:{"label":"Outros","value":"O"}})],1)],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Estado-civil"}},[_vm._v("Estado civil")]),_c('v-text-field',{staticStyle:{"margin":"0 5px","padding-top":"5px !important"},attrs:{"disabled":_vm.visualizar,"error-messages":_vm.error.estadoCivil,"clear-icon":"clear","type":'text',"outlined":false},model:{value:(_vm.atendente.estado_civil),callback:function ($$v) {_vm.$set(_vm.atendente, "estado_civil", $$v)},expression:"atendente.estado_civil"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: Casado, Solteiro, Divorciado, Viúvo\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CEP"}},[_vm._v("CEP")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cep,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo CEP é obrigatório.') ],"placeholder":"00000-000"},on:{"blur":_vm.searchCep},model:{value:(_vm.atendente.cep),callback:function ($$v) {_vm.$set(_vm.atendente, "cep", $$v)},expression:"atendente.cep"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"xxxxx-xxx\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Rua"}},[_vm._v("Logradouro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.logradouro,"disabled":_vm.visualizar,"background-color":"grey lighten-2","rules":[
                _vm.validationService.required('Campo Logradouro é obrigatório.') ],"type":'text'},model:{value:(_vm.atendente.logradouro),callback:function ($$v) {_vm.$set(_vm.atendente, "logradouro", $$v)},expression:"atendente.logradouro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Bairro"}},[_vm._v("Bairro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.bairro,"disabled":_vm.visualizar,"background-color":"grey lighten-2","rules":[
                _vm.validationService.required('Campo Bairro é obrigatório.') ],"type":'text'},model:{value:(_vm.atendente.bairro),callback:function ($$v) {_vm.$set(_vm.atendente, "bairro", $$v)},expression:"atendente.bairro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Numero"}},[_vm._v("Número")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.numero,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Número é obrigatório.') ]},model:{value:(_vm.atendente.numero),callback:function ($$v) {_vm.$set(_vm.atendente, "numero", $$v)},expression:"atendente.numero"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Complemento"}},[_vm._v("Complemento")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.complemento,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.atendente.complemento),callback:function ($$v) {_vm.$set(_vm.atendente, "complemento", $$v)},expression:"atendente.complemento"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Referencia"}},[_vm._v("Referência")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.referencias,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.atendente.referencias),callback:function ($$v) {_vm.$set(_vm.atendente, "referencias", $$v)},expression:"atendente.referencias"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Cidade"}},[_vm._v("Cidade")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cidade,"background-color":"grey lighten-2","disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Cidade é obrigatório.') ],"type":'text'},model:{value:(_vm.atendente.cidade),callback:function ($$v) {_vm.$set(_vm.atendente, "cidade", $$v)},expression:"atendente.cidade"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Estado"}},[_vm._v("Estado")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.estado,"background-color":"grey lighten-2","disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Estado é obrigatório.') ],"type":'text'},model:{value:(_vm.atendente.estado),callback:function ($$v) {_vm.$set(_vm.atendente, "estado", $$v)},expression:"atendente.estado"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mt-n3",staticStyle:{"padding-top":"0 !important"}},[_c('label',[_vm._v("Status")]),_c('v-select',{attrs:{"outlined":"","items":_vm.statusSelect,"item-text":"descricao","item-value":"id"},model:{value:(_vm.ativo),callback:function ($$v) {_vm.ativo=$$v},expression:"ativo"}})],1),_c('div',{staticClass:"col-12 form-group clearfix mb-3"},[_c('label',{staticClass:"mb-3"},[_vm._v("Unidades")]),(!_vm.visualizar)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersListaUnidades,"items":_vm.unidades,"loading":_vm.loadingData,"item-key":"cnpj","show-select":"","dense":"","hide-default-footer":""},model:{value:(_vm.unidadesRecepcionista),callback:function ($$v) {_vm.unidadesRecepcionista=$$v},expression:"unidadesRecepcionista"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersUnidadesRecepcionista,"items":_vm.unidadesRecepcionista,"loading":_vm.loadingData,"item-key":"cnpj","dense":"","hide-default-footer":""}})],1),_c('div',{staticClass:"col-12 form-group"},[(!_vm.visualizar)?_c('button',{staticClass:"\n                btn btn-app-primary btn-flex btn-rounded\n                font-weight-bold\n                justify-content-end\n              ",on:{"click":function($event){$event.preventDefault();return _vm.atualizarAssistente()}}},[(!_vm.loading)?_c('span',[_vm._v(" Salvar ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()])]),(_vm.messageSnackbar)?_c('v-snackbar',{attrs:{"color":_vm.typeMessageSnackbar,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":'white'},on:{"click":_vm.fecharSnackbar}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}],null,false,3805457681),model:{value:(_vm.messageSnackbar),callback:function ($$v) {_vm.messageSnackbar=$$v},expression:"messageSnackbar"}},[_vm._v(" "+_vm._s(_vm.messageSnackbar)+" ")]):_vm._e()],1)],1):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }