<template>
  <div>
    <div class="slimscroll-menu w-100">
      <div class="left-side-menu shadow-none">
        <div
          v-if="!isClient"
          id="sidebar-menu"
          style="height: 100%; overflow-y: auto"
        >
          <v-template v-if="!telemedicinaVisivel">
            <v-list-group color="#fff" rounded>
              <template v-slot:prependIcon>
                <v-icon style="color: #fff"> mdi-heart-plus</v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff" rounded>
                  Assistência
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getAssistencias(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>

            <v-list-group color="#fff" rounded v-if="perfilId != 57">
              <template v-slot:prependIcon>
                <v-icon style="color: #fff"> mdi-note-plus</v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff" rounded>
                  <span v-if="[1, 6].includes(perfilId)">Cadastral</span>
                  <span v-else>Orçamento</span>
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded v-if="perfilId == 1">
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getCadastrais(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <v-list color="#1DAF80" dense rounded v-else>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getCadastrais(
                      options
                    ).filter((op) =>
                      [
                        'Agenda Especialidade',
                        'Procedimento Valor',
                        'Orçamento',
                      ].includes(op.label)
                    )"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>

            <v-list-group color="#fff" rounded>
              <template v-slot:prependIcon>
                <v-icon style="color: #fff"> mdi-chart-areaspline </v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff">
                  Relatórios
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getRelatorios(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>

            <v-list-group color="#fff" rounded v-if="perfilId != 6">
              <template v-slot:prependIcon>
                <v-icon style="color: #fff"> mdi-file-document </v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff">
                  Legado
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getVetus(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>

            <v-list-group color="#fff" rounded>
              <template v-slot:prependIcon>
                <v-icon style="color: #fff"> mdi-account-details </v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff">
                  Filas
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getFilas(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>
          </v-template>
          <v-template v-else>
            <v-list-group color="#fff" rounded>
              <template v-slot:prependIcon>
                <v-icon style="color: #fff">mdi-video</v-icon>
              </template>

              <template v-slot:appendIcon>
                <v-icon style="color: #fff"> mdi-menu-down</v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-title style="color: #fff">
                  Televet
                </v-list-item-title>
              </template>

              <v-list color="#1DAF80" dense rounded>
                <v-list-item-group>
                  <v-list-item
                    v-for="(option, index) in getTelemedicina(options)"
                    :key="index"
                    @click="redirectTo(option.path)"
                  >
                    <router-link class="formata-link" :to="option.path">
                      <v-list-item-icon>
                        <v-icon
                          small
                          color="#fff"
                          v-text="option.icon"
                        ></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        class="text-start"
                        style="color: #fff"
                        v-text="option.label"
                      ></v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>
            <v-template v-if="perfilId == 1">
              <v-list-group color="#fff" rounded>
                <template v-slot:prependIcon>
                  <v-icon style="color: #fff"> mdi-note-plus </v-icon>
                </template>

                <template v-slot:appendIcon>
                  <v-icon style="color: #fff"> mdi-menu-down</v-icon>
                </template>

                <template v-slot:activator>
                  <v-list-item-title style="color: #fff">
                    Cadastral
                  </v-list-item-title>
                </template>

                <v-list color="#1DAF80" dense rounded>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(option, index) in getCadastraisTelevetAdmin(
                        options
                      )"
                      :key="index"
                      @click="redirectTo(option.path)"
                    >
                      <router-link class="formata-link" :to="option.path">
                        <v-list-item-icon>
                          <v-icon
                            small
                            color="#fff"
                            v-text="option.icon"
                          ></v-icon>
                        </v-list-item-icon>

                        <v-list-item-title
                          class="text-start"
                          style="color: #fff"
                          v-text="option.label"
                        ></v-list-item-title>
                      </router-link>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-list-group>

              <v-list-group color="#fff" rounded>
                <template v-slot:prependIcon>
                  <v-icon style="color: #fff"> mdi-chart-areaspline </v-icon>
                </template>

                <template v-slot:appendIcon>
                  <v-icon style="color: #fff"> mdi-menu-down</v-icon>
                </template>

                <template v-slot:activator>
                  <v-list-item-title style="color: #fff">
                    Relatórios
                  </v-list-item-title>
                </template>

                <v-list color="#1DAF80" dense rounded>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(option, index) in getRelatoriosTelevetAdmin(
                        options
                      )"
                      :key="index"
                      @click="redirectTo(option.path)"
                    >
                      <router-link class="formata-link" :to="option.path">
                        <v-list-item-icon>
                          <v-icon
                            small
                            color="#fff"
                            v-text="option.icon"
                          ></v-icon>
                        </v-list-item-icon>

                        <v-list-item-title
                          class="text-start"
                          style="color: #fff"
                          v-text="option.label"
                        ></v-list-item-title>
                      </router-link>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-list-group>
            </v-template>
          </v-template>
        </div>
        <div id="sidebar-menu" style="height: 100%; overflow-y: auto" v-else>
          <v-list color="#1DAF80" dense rounded>
            <v-list-item-group>
              <v-list-item
                class="mb-5"
                @click="redirectTo('dashboard-cliente')"
              >
                <v-list-item-title
                  class="text-center"
                  style="color: #fff; font-weight: bolder; font-size: medium"
                  v-text="'Principal'"
                ></v-list-item-title>
              </v-list-item>
              <v-list-item class="mb-5" @click="redirectTo('meus-pets')">
                <v-list-item-title
                  class="text-center"
                  style="color: #fff; font-weight: bolder; font-size: medium"
                  v-text="'Meus Pets'"
                ></v-list-item-title>
              </v-list-item>
              <!-- <v-list-item
                class="mb-5"
                @click="redirectTo('agendamento-cliente')"
              >
                <v-list-item-title
                  class="text-center"
                  style="color: #fff; font-weight: bolder;font-size: medium;"
                  v-text="'Agendamento'"
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item class="mb-5" @click="redirectTo('minhas-consultas')">
                <v-list-item-title
                  class="text-center"
                  style="color: #fff; font-weight: bolder; font-size: medium"
                  v-text="'Minhas Consultas'"
                ></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- <v-column>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('dashboard-cliente')"
            >
              Principal
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('dadoscadastrais')"
            >
              Meus Dados
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('meus-pets')"
            >
              Meus Pets
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('agendar_consulta')"
            >
              Agendar consulta
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('minhas-consultas')"
            >
              Minhas Consultas
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('agendar-teleconsulta')"
            >
              Agendar TeleConsulta
            </v-btn>
          </v-column> -->
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_mixin.js";
import AuthService from "../../services/auth_service";

export default {
  mixins: [VuexUsuarioMixin],
  // props: { isClient: Boolean },
  data: () => ({
    authService: AuthService.build(),
    isClient: false,
    cadastrais: [
      "cadastro-paciente",
      "unidades",
      "servidores-chamefacil",
      // "unidade-regiao",
      "prefeituras",
      // "centro_custo",
      "forma_pagamento",
      // "setor",
      "perfis",
      "medicos",
      "administrativos",
      "supervisores",
      "usuario-prefeitura",
      "assistentes",
      "formularios",
      "procedimentos",
      // "classificacao-risco",
      "procedimentosEspecialidade",
      "procedimentos-valor",
      "especialidade",
      "agendamento-especialidades",
      // "pre-agendamento",
      "tipos-procedimentos",
      "tipos-estorno",
      "exames",
      // "medicamentos",
      // "horarios",
      "especies",
      "racas",
      "convenios",
      "atendentes",
      "orcamento",
    ],
    assistencias: [
      "home",
      "fila_atendimento",
      "requisicoes-consultas",
      // "tutores",
      "tutores",
      "pets",
      "dashboard",
      "recepcao",
      "agendar_consulta",
      "agendamento_especialidade_recepcionista",
      "minhas_consultas",
      "prontuarios",
      "agendamentos",
      "meu-prontuario",
      "importacao",
      "filasatendimentomedico",
      "minhas_agendas",
      "requisicoes-exames",
      // "internacao",
    ],
    relatorios: [
      "atendimentos",
      "relatorio-comandas",
      "relatorio-itens-excluidos",
      "relatorio-procedimentos",
      "recebimentos",
      "pet-unidade",
      "tutor-unidade",
      "pets-unidade",
      "caixa-diario",
      "relatorio-procedimentos-quantitativo",
      "relatorio-atendimento-televet",
      "atendimentos-quantitativo",
    ],
    vetus: ["legado_vetus"],
    filas: ["acompanhar-filas"],
    telemedicina: [
      "telemedicina",
      "telemedicina-diponibiliadade",
      "telemedicina-consulta",
      "fila-televet",
      "tutores",
      "pets",
      "prontuarios",
    ],
    cadastraisTelevetAdmin: [
      "agendamento-especialidades",
      "cadastro-paciente",
      "unidades",
      "centro_custo",
      "forma_pagamento",
      "especialidade",
      "formularios",
      "procedimentosEspecialidade",
      "procedimentos",
      "setor",
      "perfis",
      "medicos",
      "administrativos",
      "assistentes",
      "formularios",
      "procedimentos-valor",
      "tipos-estorno",
      "exames",
      "medicamentos",
      "horarios",
      "especies",
      "racas",
      "convenios",
      "atendentes",
    ],
    relatoriosTelevetAdmin: [
      "relatorio-comandas",
      "relatorio-itens-excluidos",
      "relatorio-procedimentos",
      "relatorio-atendimento-televet",
      "recebimentos",
      "pet-unidade",
      "tutor-unidade",
      "pets-unidade",
      "caixa-diario",
    ],
    perfilId: null,
    telemedicinaVisivel: false,
  }),
  computed: {
    options() {
      const routes = this.$router.options.routes;
      const perfilId = ~~JSON.parse(sessionStorage.vuex).perfil.id;
      return routes
        .filter(({ name, menu, endpoints, perfis }) => {
          if (
            (~~JSON.parse(sessionStorage.vuex).perfil.id === 1 &&
              name === "supervisores") ||
            name == "servidores-chamefacil"
          ) {
            return true;
          }

          if (~~JSON.parse(sessionStorage.vuex).perfil.id == 4) {
            // assistente
            return ["agendar_consulta", "minhas_consultas", "pets"].includes(
              name
            );
          }

          if (~~JSON.parse(sessionStorage.vuex).perfil.id == 6) {
            return [
              "tutores",
              "prontuarios",
              "pets",
              "procedimentos-valor",
              "relatorio-comandas",
              "pet-unidade",
              "acompanhar-filas",
              "relatorio-procedimentos",
            ].includes(name);
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 2 &&
            name === "filasatendimentomedico"
          ) {
            // medico
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 2 &&
            name === "minhas_agendas"
          ) {
            // medico
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 3 &&
            name === "fila_atendimento"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 2 &&
            name === "agendamentos"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 4 &&
            name === "agendamentos"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 4 &&
            name === "tutores"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 4 &&
            name === "minhas_consultas"
          ) {
            // assistente
            return false;
          }

          if (![1, 56, 57].includes(perfilId) & (name === "recepcao")) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 3 &&
            name === "dashboard"
          ) {
            // assistente
            return false;
          }

          if (
            ![1, 57].includes(~~JSON.parse(sessionStorage.vuex).perfil.id) &&
            (name === "pet-unidade" || name === "caixa-diario")
          ) {
            // assistente
            return false;
          }

          const perfilMatch = perfis
            ? perfis.includes(JSON.parse(sessionStorage.vuex).perfil.id)
            : true;
          const usuarioPossuiPeloMenosUmEndpoint = endpoints?.some((endpoint) =>
            this.authService.temPermissao(endpoint)
          );
          return (
            perfilMatch &&
            menu &&
            (usuarioPossuiPeloMenosUmEndpoint || !endpoints?.length)
          ); // se a lista de endpoints for vazia, libera
        })
        .map(({ label, name, path, icon }) => ({
          label: (() => {
            if (
              name === "pets" &&
              JSON.parse(sessionStorage.vuex).perfil.id === 4
            ) {
              // se a rota for dos pets e o usuário logado for cliente
              return "Meus pets";
            }

            if (
              name === "agendamentos" &&
              ~~JSON.parse(sessionStorage.vuex).perfil.id === 2
            ) {
              return "Minhas agendas";
            }
            return label;
          })(),
          name,
          path,
          icon,
        }));
    },
  },
  methods: {
    isCadastral(option) {
      return this.cadastrais.includes(option.name);
    },
    getCadastrais(options) {
      return options.filter((option) => this.isCadastral(option));
    },
    isVetus(option) {
      return this.vetus.includes(option.name);
    },
    getVetus(options) {
      return options.filter((option) => this.isVetus(option));
    },
    isAssistencia(option) {
      return this.assistencias.includes(option.name);
    },
    getAssistencias(options) {
      return options.filter((option) => this.isAssistencia(option));
    },
    isRelatorio(option) {
      return this.relatorios.includes(option.name);
    },
    getRelatorios(options) {
      return options.filter((option) => this.isRelatorio(option));
    },
    isFilas(option) {
      return this.filas.includes(option.name);
    },
    getFilas(options) {
      return options.filter((option) => this.isFilas(option));
    },
    isTelemedicina(option) {
      return this.telemedicina.includes(option.name);
      // return option.name;
    },
    getTelemedicina(options) {
      return options.filter((option) => this.isTelemedicina(option));
    },
    isCadastraisTelevetAdmin(option) {
      return this.cadastraisTelevetAdmin.includes(option.name);
      // return option.name;
    },
    getCadastraisTelevetAdmin(options) {
      return options.filter((option) => this.isCadastraisTelevetAdmin(option));
    },
    isRelatoriosTelevetAdmin(option) {
      return this.relatoriosTelevetAdmin.includes(option.name);
      // return option.name;
    },
    getRelatoriosTelevetAdmin(options) {
      return options.filter((option) => this.isRelatoriosTelevetAdmin(option));
    },
    redirectTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    this.perfilId = this.$_GETTER_perfil.id;
    this.isClient = this.$_GETTER_perfil.descricao === "Paciente";

    const perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
    const unidadeId = JSON.parse(sessionStorage.vuex).unidade.id;
    if ([1, 2, 57].includes(perfilId) && unidadeId == 24) {
      this.telemedicinaVisivel = true;
    }
  },
};
</script>
<style scoped>
.menu-item {
  margin: 1rem 0 1rem 0;
}

.list-unstyled {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1daf80;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 20px;
}

#side-menu {
  text-align-last: left;
}

.left-side-menu {
  background-color: #1daf80;
  /*overflow-y: scroll;*/
}

#sidebar-menu .nav-second-level {
  padding-left: 0;
}

#sidebar-menu > ul > li > a,
#sidebar-menu .nav-second-level li a {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

#sidebar-menu .nav-second-level li a {
  display: flex;
}

#sidebar-menu > ul > li > a:hover,
#sidebar-menu .nav-second-level li a:hover,
#sidebar-menu > ul > li > a:active,
#sidebar-menu .nav-second-level li a:active {
  color: #fff;
  opacity: 0.5;
}

#sidebar-menu .nav-second-level li a i {
  display: inline-block;
  line-height: 1.25rem;
  margin: 0 10px 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  font-size: 14px;
  color: #fff;
}

.bg-selected-menu {
  background-color: #fff;
  color: #1daf80 !important;
}

.formata-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0;
}
</style>
